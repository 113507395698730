.marquee-vertical-wrapper {
  display: flex;
  justify-content: center;
  overflow: hidden;
  max-height: 750px;
  padding: 20px 0;
  margin-top: 4rem;
}

.marquee-vertical-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 350px;
}

.marquee-vertical-content {
  display: flex;
  flex-direction: column;
  animation: scroll-vertical 10s linear infinite;
}

.marquee-vertical-container.reverse .marquee-vertical-content {
  animation-direction: reverse;
}

@keyframes scroll-vertical {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
}

.review-card {
  flex: 0 0 auto;
  padding: 10px 10px 0px !important;

  border: 1px solid #ddd;
  backdrop-filter: blur(10px);
  border-radius: 10px;
  margin: 10px;
  margin-top: 40px;
  transition: background 0.3s ease-in-out;
  backdrop-filter: blur(10px) !important;
  box-shadow: 1px 1px 1px #040404, -1px -1px 1px #040404;
}


.review-header {
  display: flex;
  align-items: center;
  gap: 10px;
}
.review-body{
  position: relative;
  top: -30px;
  text-align: center;
  background-color: aliceblue;
  padding: 10px;
  border-radius: 10px;
}
.avatar {
  width: 100px !important;
  height: 100px !important;
  border-radius: 50%;
  object-fit: cover;
  border-top: 5px solid #131313;
  border-right: 5px solid #f8d00c;
  border-bottom: 5px solid #f8d00c;
  border-left: 5px solid #131313;
  margin: 0 auto;
  position: relative;
  top: -50px;
}

.name {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.username {
  font-size: 14px;
  color: #222;
  margin-top: 4px;
  font-weight: 500;
}

.review-text {
  margin-top: 4px;
  font-size: 14px;
  color: #222;
  font-weight: 500
}

@media screen and (max-width: 600px) {
  .reverse {
    display: none !important;
  }
}
